@import '../../styles/_variables';

.map-container {
  position: relative;
  height: 360px;
  width: 100%;
  margin-top: 16px;

  &__zoom-in,
  &__zoom-out,
  &__location {
    cursor: pointer;
    position: absolute;
    right: 16px;
    bottom: 62px;
    z-index: 2000;
    width: 44px;
    height: 44px;
    background: $clear-white;
    border: 1px solid $black-16;
    box-shadow: 0 6px 6px $black-16;
    border-radius: 8px;
  }

  &__zoom-out {
    bottom: 16px;
  }

  &__location {
    bottom: 122px;
  }

  &__search-container {
    position: absolute;
    top: 16px;
    left: 16px;
    width: calc(100% - 32px);
    z-index: 1000;

    &__search {
      width: 100%;
      height: 44px;
      padding: 12px 16px;
      border: 1px solid $black-16;
      box-shadow: 0 6px 6px $black-16;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;

      &:read-only {
        color: $black-64;
      }
    }

    &__search-result {
      height: fit-content;
      padding: 12px 16px;
      font-size: 16px;
      line-height: 32px;
      background: $clear-white;

      &:hover {
        background: #ebebeb;
      }
    }
  }
}
