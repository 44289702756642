@import '../../../styles/variables';
@import '../../../styles/mixins';

.specialist-aside {
  position: relative;
  cursor: default;
  padding-top: 40px;

  &__closed {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 32px;

    &__title {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
    }

    &__desc {
      font-size: 14px;
      line-height: 20px;
      color: $black-72;
    }
  }

  &__opened {
    position: relative;
    display: block;
    cursor: default;
    max-height: 100vh;
    max-width: 720px;
    margin-left: 6px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 0 24px;

    &__avatar {
      width: 104px;
      height: 104px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &__common-info {
      display: flex;
      flex-direction: column;

      &__name {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
      }

      &__last-name {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__status {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 18px;

      &__text {
        font-size: 14px;
        line-height: 16px;
        color: $black-64;
      }
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 24px;

    &__warning {
      padding: 16px 24px;
      display: flex;
      gap: 16px;
      flex-direction: row;

      &__icon {
        width: 24px;
        height: 24px;
        color: $black-32;
      }
    }

    &__message {
      padding: 16px 24px;

      label {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
          font-size: 14px;
          line-height: 14px;
        }

        textarea {
          margin-top: 2px;
          min-height: 56px;

          &:focus + .specialist-aside__body__label-icon img {
            filter: none;
          }
        }
      }
    }

    &__common-card {
      padding: 14px 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }

      &__sub-title {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: $black-72;
      }

      &__link {
        font-size: 16px;
        line-height: 20px;
        text-align: right;
        text-decoration-line: underline;
        color: #2662c9;
        margin-left: auto;
        cursor: pointer;
      }

      &__contacts-container {
        align-items: inherit;
        flex-direction: column;
      }

      &__contacts {
        display: flex;
        gap: 24px;
      }

      &__contact {
        font-size: 14px;
        line-height: 18px;
        text-decoration-line: underline;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    &__label-icon {
      position: absolute;
      bottom: 24px;
      right: 16px;
      border: none;
      background: transparent;
      cursor: pointer;

      img {
        filter: invert(55%) sepia(6%) saturate(17%) hue-rotate(315deg)
          brightness(95%) contrast(87%);
      }
    }

    &__file-card {
      display: flex;
      flex-direction: column;
      align-items: initial;
      gap: 24px;

      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &__file-error {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $black-87;
      }

      &__close-error {
        border: none;
        background: transparent;
        cursor: pointer;
        height: 24px;
        color: $black-64;
      }

      &__file-name {
        font-size: 16px;
        line-height: 20px;
      }

      &__file-del {
        border: none;
        background: transparent;
        cursor: pointer;

        color: $red-rufous-base;
      }

      &__send-button {
        width: fit-content;
        min-width: 180px;
      }
    }
  }

  &__notification {
    position: absolute;
    bottom: 16px;
    right: 24px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 14px 16px 10px 16px;
    background: $green-celadon-extra;
    border-left: 4px solid $green-pantone-base;

    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    &__link {
      font-size: 14px;
      line-height: 20px;
      text-decoration: underline;
      background: transparent;
      border: none;
      cursor: pointer;
      align-self: start;
    }

    &__close-button {
      position: absolute;
      top: 12px;
      right: 16px;
      margin-left: auto;
      height: 24px;
      background: transparent;
      border: none;
      cursor: pointer;
      color: $black-64;
    }
  }
}

@media screen and (max-width: 769px) {
  .specialist-aside {
    padding-top: 24px;

    &__closed {
      padding: 0;
      align-items: center;
      gap: 16px;

      &__button {
        border: none;
        background: transparent;
        height: 24px;
        cursor: pointer;
      }

      &__title {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        transform: rotate(180deg);
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__opened {
      position: absolute;
      width: 100%;
      max-width: 100%;
      padding-top: 48px;

      &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      &__desc {
        padding-left: 56px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $black-72;
      }

      &__header {
        padding: 32px 0 16px;
        gap: 16px;
      }
    }

    &__header {
      padding-top: 32px;
      align-items: flex-start;

      &__avatar {
        width: 52px;
        height: 52px;
      }

      &__common-info {
        &__name {
          font-size: 14px;
          line-height: 16px;
        }

        &__last-name {
          font-size: 12px;
          line-height: 14px;
        }
      }

      &__status {
        margin-top: 12px;

        img {
          width: 12px;
          height: 12px;
        }

        &__text {
          font-size: 10px;
          line-height: 16px;
        }
      }
    }

    &__body {
      margin: 0 8px;

      &__warning {
        gap: 8px;
        span {
          font-size: 12px;
          line-height: 16px;
        }
      }

      &__message {
        label {
          font-size: 12px;
          line-height: 14px;
        }
      }

      &__common-card {
        padding: 16px;

        &__title {
          font-size: 14px;
          line-height: 16px;
        }

        &__sub-title {
          font-size: 8px;
          line-height: 10px;
        }

        &__link {
          font-size: 12px;
          line-height: 14px;
        }
      }

      &__file-card {
        gap: 16px;

        &__file-name {
          font-size: 14px;
          line-height: 16px;
        }

        &__send-button {
          font-size: 14px;
          line-height: 20px;
          padding: 0 16px;
          min-width: 0;
          width: fit-content;
        }
      }
    }
  }
}
