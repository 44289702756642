@import '../../styles/_variables';

.modal-auth {
  position: fixed;
  background-color: $black-48;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &__login-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $clear-white;
    filter: drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275))
      drop-shadow(0px 12.5216px 8px rgba(0, 0, 0, 0.08))
      drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));
    border-radius: 16px;
    padding: 32px 40px;
    width: 350px;

    &__logo {
      width: 136px;
      height: 32px;
      margin-bottom: 40px;
    }

    &__text {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 32px;
    }

    &__phone-number-container {
      display: flex;
      gap: 16px;
      padding: 12px 24px;
      margin-bottom: 24px;
      border: 1px solid $black-16;
      border-radius: 8px;

      &__logo {
        object-fit: cover;
        width: 20px;
        height: 20px;
        border: 1px solid $black-32;
        border-radius: 50%;
      }

      &__input {
        border: none;
        width: 180px;
        //margin-left: auto;
        font-size: 16px;
        line-height: 20px;

        &:focus {
          outline: none;
        }
      }
    }

    &__back-btn {
      left: 21px;
    }

    &__close-btn,
    &__back-btn {
      position: absolute;
      right: 21px;
      top: 21px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: none;
      background-color: transparent;
    }

    &__error {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal-auth {
    background-color: $clear-white;
    z-index: 10;

    align-items: flex-start;
    justify-content: center;

    &__login-container {
      background: $clear-white;
      filter: none;
      border-radius: 0;
      padding: 24px 40px;
      width: 100%;
      height: 100%;
    }
  }
}
